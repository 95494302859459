.container3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin: 60px auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}
.row {
  display: flex;
  justify-content: space-between;
  margin: 12px;
  border-bottom: inset;
}
.col-md-8 {
  font-size: 16px;
  /* font-weight: bold; */
  color: #333;
  height: 38px;
  display: flex;
  align-items: center;
  width: fit-content;
}
.col-md-4 {
  display: flex;
  justify-content: flex-end;
  /* width: 320px; */
}
.btn-primary {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.btn-primary {
  background-color: #007bff;
  color: #fff;
}
.btn-primary:hover {
  background-color: #0067cc;
}
#percentage {
  font-weight: bold;
  color: #38a3a5;
}
#search-bar {
  /* width: 300.5px; */
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 5px;
  border-radius: 5px;
}
#search-bar:disabled {
  background-color: rgba(211, 211, 211, 0.6);
  cursor: not-allowed;
}
#search-bar:focus {
  border: 1px solid #ccc;
  outline: none !important;
}
#search-bar2 {
  width: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  /* margin-left: 5px; */
  border-radius: 5px;
}
#search-bar2:focus {
  border: 1px solid #ccc;
  outline: none !important;
}
.suggestion-box1 {
  position: relative;
  /* width: 300.5px; */
  /* Match the width of the search box */
  max-height: 100px;
  overflow-y: auto;
  /* margin-left:44px; */
  border: 1px solid #ccc; /* Dark gray border */
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  display: none;
  background-color: #fff;
  z-index: 1;
  top: 100%; /* Position it below the search box */
  /* left: 14.6%; Align with the left edge of the search box */
}
.suggestion-item {
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  height: fit-content;
  overflow: hidden; 
}
.suggestion-item:hover {
  background-color: #87ceeb;
}
.suggestion-container1 {
  position: absolute;
  /* margin-left: 130.6px; */
  top: 11.05em;
  /* display: none; */
}
/* #suggestion-container2,::-webkit-scrollbar {
    display: none;
  }
  .suggestion-container3,::-webkit-scrollbar {
    display: none;
  } */
.search-container {
  display: inline-block;
}
@media screen and (width: 67%) {
  .suggestion-box1 {
    width: 300px;
  }
}
@media screen and (width: 110%) {
  .suggestion-box1 {
    width: 300.5px;
  }
}
@media screen and (width: 90%) {
  .suggestion-box1 {
    width: 300.5px;
  }
}
.accordion {
  background-color: #f5f5f5;
  color: #444;
  /* cursor: pointer; */
  /* padding: 12px; */
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  /* margin: 12px; */
  /* font-weight: bold; */
  color: #333;
  transition: 0.4s;
}
/* .active, .accordion:hover {
    background-color: #ccc;
  } */
.accordion:after {
  /* content: '\002B'; */
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.panel {
  /* padding: 0 18px; */
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.panel1 {
  /* padding: 0 18px; */
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.panel2 {
  /* padding: 0 18px; */
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
#suggestion-container2 {
  border: 1px solid #ccc; /* Dark gray border */
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}
.suggestion-container3 {
  border: 1px solid #ccc; /* Dark gray border */
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  overflow-x: clip;
  /* background-color: #fff; */
}

.accordion1 {
  background-color: #f5f5f5;
  color: #444;
  /* cursor: pointer; */
  /* padding: 12px; */
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  /* margin: 12px; */
  /* font-weight: bold; */
  color: #333;
  transition: 0.4s;
}
/* .active, .accordion:hover {
    background-color: #ccc;
  } */
.accordion1:after {
  /* content: '\002B'; */
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion2 {
  background-color: #f5f5f5;
  color: #444;
  /* cursor: pointer; */
  /* padding: 12px; */
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  /* margin: 12px; */
  /* font-weight: bold; */
  color: #333;
  transition: 0.4s;
}
/* .active, .accordion:hover {
    background-color: #ccc;
  } */
.accordion2:after {
  /* content: '\002B'; */
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}