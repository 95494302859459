h1 {
  text-align: center;
  color: white; /* White color for text */
  background-color: #007bff; /* Sky blue background */
  padding: 20px; /* Add padding to create a background strip effect */
}
h2 {
  text-align: left;
  margin: 20px;
}
/* Style for the search forms */
.search-form {
  text-align: left;
  margin: 20px;
  padding-left: 30px;
}
/* Style for form labels */
label {
  font-weight: normal;
  font-size: 1.3rem;
  color: #333; /* Dark gray color */
}
/* Style for text input fields */
.search-form input[type="text"] {
  width: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  margin-left: 5px; /* Light gray border */
  border-radius: 5px;
  /* border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%; */
}
.search-form input[type="text"]:focus {
  border: 1px solid #ccc;
  outline: none !important;
}
/* Style for the submit button */
input[type="submit"] {
  background-color: #007bff; /* Blue color */
  color: #fff; /* White color */
  padding: 5px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
/* Style for the submit button on hover */
input[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue color */
}
#logo {
  width: 100px;
}
#title1 {
  padding-top: 60px;
  font-size: 20px;
}
/* ===== Google Font Import - Poppins ===== */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
  /* transition: none !important; */
}
/* ===== Colours ===== */
:root {
  --body-color: #fff;
  --nav-color: #e5e4e2;
  --side-nav: #010718;
  --text-color: #42b0d5;
  --search-bar: #f2f2f2;
  --search-text: #010718;
}
body {
  height: 100vh;
  background-color: var(--body-color);
}
body.dark {
  --body-color: #18191a;
  --nav-color: #242526;
  --side-nav: #242526;
  --text-color: #ccc;
  --search-bar: #242526;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: var(--nav-color);
  z-index: 100;
  align-items: flex-start;
}
body.dark .navbar {
  border: 1px solid #393838;
}
.navbar .nav-bar {
  position: relative;
  height: 100%;
  max-width: 1000px;
  width: 100%;
  background-color: var(--nav-color);
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
}
.navbar .nav-bar .logo a {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
  text-decoration: none;
  padding: 5px;
  white-space: nowrap;
  /* width: max-content;
  display: block; */
}
.menu .logo-toggle {
  display: none;
}
.nav-bar .nav-links {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.nav-bar .nav-links li {
  margin: 0 5px;
  list-style: none;
  display: inline;
}
.nav-links li a {
  position: relative;
  font-size: 17px;
  font-weight: 400;
  color: var(--text-color);
  text-decoration: none;
  padding: 3px;
  margin-left: 15px;
  float: left;
}
.nav-links li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #42b0d5;
  bottom: 0;
  left: 0;
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.nav-links li:hover a::before {
  transform-origin: center;
  transform: scaleX(1);
}
.nav-bar .darkLight-searchBox {
  display: flex;
  align-items: center;
}
/* @media (max-width: 790px) {
  nav .nav-bar .sidebarOpen {
    display: block;
  }
  nav.active .nav-bar .navLogo a {
    opacity: 0;
    transition: all 0.3s ease;
  }
  .nav-bar .nav-links {
    flex-direction: row;
  }
  .nav-links li a {
    display: block;
    margin-top: 20px;
  }
} */
.container1 {
  padding-top: 10px;
  padding-left: 30px;
  font-size: 15px;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
}
h2 {
  font-size: 20px;
  padding-top: 15px;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 3px 5px lightblue;
}
ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
a {
  color: #18272f;
  position: relative;
  text-decoration: none;
}
a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: #42b0d5;
  bottom: 0;
  left: 0;
  transform-origin: center;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
a:hover::before {
  transform-origin: center;
  transform: scaleX(1);
}
.create-form input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  height: 15%;
}
.create-form input[type="text"]:focus {
  border: 2px solid #42b0d5;
  outline: none !important;
}
.create-form input[type="submit"] {
  width: 25%;
  background-color: #42b0d5;
  color: white;
  margin: 5px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 20%;
}
.create-form input[type="submit"]:hover {
  background-color: #0073ab;
}
.create-form {
  border-radius: 5px;
  background-color: antiquewhite;
  padding: 20px;
  float: right;
  width: 30%;
  margin-top: 75px;
  margin-right: 5vw;
  display: none;
}
.create-form label {
  font-size: 15px;
}
.create {
  height: 200px;
  width: 100%;
}
.create-form #cancel {
  width: 25%;
  color: white;

  margin: 5px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 20%;
  float: right;
  background-color: #e75858;
}
.create-form #cancel:hover {
  float: right;
  background-color: #b75353;
}
.table-container {
  /* height: 400px; */
  /* overflow: auto; */
  display: table-row-group;
  /* flex-grow: 1; */
 
  width: 100%; /* Adjust width as needed */
  /* margin: auto; */
  
}
.create-form .suggestion-container {
  position: relative;
  margin-top: -8px; 
  width: 100%;
  margin-bottom: 10px;
}

.create-form .suggestion-box {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 4px;
}

.create-form .suggestion-container2 {
  position: relative;
  margin-top: -8px; 
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.create-form .suggestion-box2 {
  width: 100%;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 4px;
}

.scrollable-suggestions {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 5px;
  margin-left: 5px;
}

.suggestion-item2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: default;
  font-size: 12px;
  height: fit-content;
}
.suggestion-item2:hover {
  background-color: #87ceeb;
}
.suggestion-item2 input[type="checkbox"] {
  margin-left: 10px;
  cursor: pointer;
}
.suggestion-box2 #done {
  position: relative;
  top: 0px;
  margin-bottom: 10px;
  width: 10%;
  color: white;
  margin: 5px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 25%;
  float: right;
  background-color: #42b0d5;
}

.suggestion-box2 #done:disabled {
  background-color: #87CEEB;
  cursor: not-allowed;
}

.suggestion-box2 #cancel {
  position: relative;
  top: 0px;
  margin-bottom: 10px;
  width: 10%;
  color: white;
  margin: 5px;
  margin-right: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 25%;
  float: right;
  background-color: #e75858;
}

.custom-table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
   /* Ensures columns are fixed size */
}

.custom-table thead {
  background-color: #87CEEB;
  /* color: white; */
  position: sticky;
  top: 0;
  /* z-index: 1; */
   /* Ensures the header is on top */
  display: table;
  width: 100%;
}

.custom-table th, .custom-table td {
  /* border: 1px solid #87CEEB; */
  /* width: auto; */
  padding: 8px;
  text-align: left;
}



.custom-table th{
  text-align: center;
}

.custom-table tbody tr:nth-child(even) {
  /* background-color: #f2f2f2; */
}

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.custom-table:not(.exclude-width-rule) th:not(:last-child), 
.custom-table:not(.exclude-width-rule) td:not(:last-child) {
  width: 9vw; /* Let other columns take remaining space */
}

.custom-table th:last-child, .custom-table td:last-child {
  width: 4vw; /* Fixed small width for the last column */
  
}

.custom-table th {
  background: #87CEEB; /* Add a background color to prevent cells from showing through */
  position: sticky;
  top: 0;
}
.custom-table td, .custom-table th{
  /* min-width: 150px; 
  min-height: 150px; 
  max-height: 200px; */
  white-space: normal; /* Allows text to wrap to next line */
  word-wrap: break-word; /* Breaks long words to next line */
}
.custom-table tbody {
  display: grid;
  overflow-y: auto;
  border: 1px solid #87CEEB;
  border-bottom: none;
  
  overflow-x: hidden;
  height: 35vh;
  min-height: 300px;
  /* max-height: 60vh;  */
}


.custom-table tbody tr {
  display: table;
  width: 100%;
  height: auto;
  table-layout: fixed;
}
.custom-table th{
  border: 2px solid #87CEEB;
}
.custom-table tbody tr:last-child td {  
  border-bottom: none;
}
.custom-table thead th:last-child {
border-right: none;
}
.suggestion-box {
 
  max-height: 100px;
  overflow-y: auto;
  /* margin-left: 14px; */
  border: 1px solid #ccc; /* Dark gray border */
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  background-color: #fff;
  z-index: 1;
  display: none;
  margin-left: 5px;


}
.suggestion-item {
  padding: 10px;
  cursor: pointer;
  font-size: 12px;
  height: fit-content;
}
.suggestion-item:hover {
  background-color: #87ceeb;
}
.suggestion-container {
  
 
}
* {
  body {
    font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
      sans-serif;
  }
}
/* .suggestion-box::-webkit-scrollbar {
  display: none;
} */
#icon:hover {
  cursor: pointer;
}
.confirmation-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.confirmation-popup p {
  margin-bottom: 15px;
}
.confirmation-popup button {
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.confirmation-popup button.yes {
  background-color: rgba(233, 178, 183, 1);
  color: #fff;
}
.confirmation-popup button.no {
  background-color: #87ceeb;
  color: #fff;
}
.centered-elements {
  text-align: center;
}
.commit-button {
  /* margin: 7px; */
  text-align: end;
  width: 100%;
}
.landing-container {
  position: relative;
  width: 100%;
  height: 100%;
}
/* Make the image responsive */
.landing-container img {
  width: 100%;
  height: 100%;
}
/* Style the button and place it in the middle of the container/image */
.landing-container .btn {
  position: absolute;
  top: 60%;
  left: 8%;
  transform: translate(-50%, -50%);
  background-color: #42b0d5; /* Blue color */
  color: #fff; /* White color */
  padding: 5px 16px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 45px;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
}
.greeting {
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translate(-50%, -50%);
  font-size: 45px;
  font-family: "Maersk Text", -apple-system, BlinkMacSystemFont, "Microsoft JhengHei", "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑",
    sans-serif;
  color: #fff; /* White color */
  padding: 5px 16px;
  margin-left: 100px;
}
.menu {
  display: flex;
  align-items: center;
}
.user-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #42b0d5;
}
.user-profile span {
  margin-right: 10px;
}
.dropdown-menu {
  position: absolute;
  top: 115%;
  left: 2vw;
  display: block;
  background-color: var(--nav-color);
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 12px;
  border-top: none;
  border-radius: 0 0 5px 5px;
}
.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu li {
  padding: 5px;
  cursor: pointer;
  display: block;
}
.dropdown-menu li a {
  color: #42b0d5;
  text-decoration: none;
}
.user-profile {
  margin-left: auto;
}
@media screen and (width: 90%) {
  .suggestion-box {
    width: 300.5px;
  }
  tbody {
    display: -webkit-inline-box;
  }
  thead tr {
    display: -webkit-inline-box;
  }
}
@media screen and (width: 110%) {
  .suggestion-box {
    width: 300.5px;
  }
  tbody {
    display: -webkit-inline-box;
  }
  thead tr {
    display: -webkit-inline-box;
  }
}
/* tbody::-webkit-scrollbar {
  display: none;
} */
@media screen and (width: 67%) {
  .suggestion-box {
    width: 300px;
  }
  tbody {
    display: -webkit-inline-box;
  }
  thead tr {
    display: -webkit-inline-box;
  }
}
.rating {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  /* padding-top: 30px; */
  text-align: center;
}
.like,
.dislike {
  display: inline-block;
  cursor: pointer;
  margin: 10px;
}
.dislike:hover,
.like:hover {
  transition: all 0.1s ease-in-out;
  color: #87ceeb;
  transform: scale(1.2);
}
iframe {
  display: none;
}
#atlwdg-frame {
  display: block;
}
.relevant-box {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 6px;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.8em;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: 2px solid #0073ab;
  border-radius: 999px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
  cursor: pointer;
}
.badge:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
  background-color: rgb(236, 235, 235);
}
.card {
  /* width: 300px; */
  /* background-color: #f8f9fa; */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  /* margin: 20px; */
  background-color: #d9d9d92b;
  border-radius: 30px;
  box-shadow: 0px 4px 4px #00000040;
  height: 278px;
  width: 272px;
  margin-left: 5%;
  margin-right: 5%;
}
.card-content {
  padding: 20px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}
.card-feedback {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: center;
}
.card-author {
  font-style: italic;
  color: #777;
  margin-top: 10px;
  text-align: right;
}
/* App.css */
.LandingPage1 {
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
}
.content-wrapper {
  width: 100%;
  /* Adjust as needed */
  /* padding: 20px; */
  /* box-sizing: border-box; */
}
.maersk-logo-RGB {
  height: 50px;
  width: 120px;
}
.header {
  height: 352px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 50px;
  background-image: url("../public/images/Laura\ Mærsk\ -\ Water\ Salute\ 12\ september\ 2023_K9A8886\ 1.png");
}
.text-wrapper {
  color: #000000;
  font-size: 32px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}
.intro-group {
  position: absolute;
  bottom: 20px;
  left: 5%;
}
.text-wrapper-2 {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 10px;
  width: 300px;
  height: 70px;
}
.text-wrapper-3 {
  color: #ffffff;
  background-color: #0073ab;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 400;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.content {
  display: flex;
  flex-direction: column;
 
  margin: 0 auto;
  align-items: center;
  padding-left: 8%;
  padding-right: 8%;
  /* margin-top: 5%; */
  /* margin-bottom: 5%; */
}
.content-item {
  margin-bottom: 30px;
  text-align: center;
}
.transition-heading {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline;
  transition: none;
}
.transition-paragraph {
  font-size: 18px;
  color: #333333;
  line-height: 1.5;
}
.transition-heading,
.transition-paragraph {
  position: relative;
  overflow: hidden;
  transition: none  ;
}
.animate-in {
  animation: slideFromBottom 0.5s ease-in-out forwards;
}
.animate-out {
  animation: slideToTop 0.5s ease-in-out forwards;
}
@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideToTop {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
.link-style{
  position: relative;
  cursor: pointer;
  display: block;

  width: fit-content;


}
 
 
.link-style::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0073ab;
  transition: width .3s;
}
#filter{
  height: 340px;
  display: flex;
  flex-direction: column;
}
 
.link-style:hover::after {
  width: 100%;
}
#suggestion-container2 {    
  border: 1px solid #ccc; /* Dark gray border */
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}
.extract-button {
  width: 90%;
}
.table-main-container {
  width: 90%;
}

@media (max-width: 1436px) {
  /* Your styles here */
  .nav-links li a{
    font-size: 14px;
  }
  nav .nav-bar .logo a{
    font-size: 18px;
  }
  .user-profile span{
    font-size: 12px;

  }
  #filter{
    height: 340px;
  }
  .extract-button {
    width: 95%;
  }
  .table-main-container {
    width: 95%;
  }
}
@media (max-width: 1229px) {
  /* Your styles here */
  .nav-links li a{
    font-size: 12px;
  }
  nav .nav-bar .logo a{
    font-size: 18px;
  }
  .user-profile span{
    font-size: 12px;

  }
  #filter{
    height: 365px;
  }
  .extract-button {
    width: 95%;
  }
  .table-main-container {
    width: 95%;
    font-size:0.8rem ;
  }
}
@media (max-width: 1024px) {
  /* Your styles here */
  .nav-links li a{
    font-size: 10px;
  }
  .navbar .nav-bar .logo a{
    font-size: 16px;
  }
  .user-profile span{
    font-size: 10px;

  }
  .navbar .nav-bar{
    width: 0;
  }
  #filter{
   height: 365px;
   
  }
  .extract-button {
    width: 95%;
  }
  .table-main-container {
    width: 95%;
    font-size:0.8rem ;
  }
}

@media (min-width: 2133px) {
  /* Your styles here */
  
  #filter{
   height: 375px;
   
  }
}

@media (min-width: 2400px) {
  /* Your styles here */
  
  #filter{
   height: 400px;
   
  }
}
@media (min-width: 1800px) {
  /* Your styles here */
  
  #filter{
   height: 360px;
   
  }
}


.container {
  padding: 20px;
  max-width: 40vw;
  width: fit-content;
  margin: 0 auto;
  
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

:root {
  --background-color: gray;
  --border-radius: 3px;
  --box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
}

.server-list {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px;
  max-height: 30vh;
  overflow-y: auto;
  /* background: var(--background-color); */
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 10px;
}

.server-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15%;
}

.server-row label {
  flex: 1;
  margin-right: 20px;
  font-weight: 500;
}

.server-row select {
  flex: 2;
  padding: 5px;
  font-size: 1rem;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.edit-button,
.discard-button {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
}

.edit-button {
  background-color: #42B0D5;
  color: white;
  border: none;
  border-radius: 5px;
}

.discard-button {
  background-color: #FA6A55;
  color: white;
  border: none;
  border-radius: 5px;
}
.custom-select {
  /* appearance: none; */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
}

.custom-select:focus {
  border-color:  #0073AB;
  box-shadow: 0 0 5px #B5E0F5;
  outline: none;
}

.custom-select option {
  padding: 10px;
}
.custom-select option {
  background-color: #fff;
  color: #333;
  padding: 10px;
}

.custom-select option:disabled {
  color: #999;
}
.filters
{
  min-height: 80px;
}

@media (max-width: 600px) {
  .server-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .server-row label {
    margin-bottom: 10px;
  }

  .action-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .edit-button,
  .discard-button {
    width: 100%;
    margin-bottom: 10px;
  }
}



.css-1xif2b4-MuiPopper-root-MuiDataGrid-menu {
  transition: none;
  
}

.css-n3z9fz-MuiPopper-root-MuiDataGrid-panel{
  transition: none;
}
[class*="MuiTooltip-popper"] {
  transition: none;
}
/* [class*="MuiChartsTooltip-root"] {
  transition: none;
} */


.dashboard-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.dashboard-item {
  flex: 1 1 calc(33.333% - 24px);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .dashboard-item {
    flex: 1 1 100%;
  }
}

.card-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.pie-chart {
  flex: 1;
}

.card-content {
  flex: 1;
  
}
.server-tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 75vw;
  overflow-x: auto;
}

.server-tabs::-webkit-scrollbar {
  height: 8px;
}

.server-tabs::-webkit-scrollbar-thumb {
  background-color: #aeadad;
  border-radius: 10px;
}

.server-tabs::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

.server-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.server-tab {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.server-tab.active {
  background-color: white;
  border-bottom: 3px solid #42b0d5;
}

.server-info {
  padding: 10px;
}

.server-info-container {
  width: fit-content;
  padding: 0 20px;
}

.server-info-title {
  margin-bottom: 5px;
  font-size: 1.39rem;
}

.server-info-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  max-height: 55vh;
  overflow-y: auto;
}

.server-info-column {
  flex: 1;
  margin-right: 90px;
  width: 650px;
}

.server-info-item {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 5px;
  align-items: center;
  width: 100%;
}

.server-info-label {
  font-size: 1rem;
  margin-right: 10px;
}

.server-info-input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 60%;
  background-color: rgba(211, 211, 211, 1);
  text-align: center;
  font-size: 1rem;

}