
.form-row{
    flex-direction: row;
    display: flex;
     justify-content: space-between;
     margin-top:20px
}


@media (max-width: 1000px) {
    .form-row {
        flex-direction: column;
        display: flex;
         justify-content: space-between;
         margin-top:20px
    }
  
    .form-group {
      flex: 1 1 100%; /* Full width for smaller screens */
    }
  }
