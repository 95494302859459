.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust the height of the container as needed */
  }
 
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
 
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
 