.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 35vw;
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  h2 {
    margin-top: 0;
    
  }
  
  .download-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .file-input {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  
  .upload-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .status-message {
    margin-top: 20px;
  }
  
  .status-message.error {
    color: red;
  }